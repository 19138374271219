<template>
  <header
    class="sticky top-0 right-0 w-full z-20 bg-body h-16 px-8 text-theme-500 flex justify-between items-center leading-none"
  >
    <!-- Left side -->
    <div>
      <!-- Burger menu -->
      <app-icon
        library="coolicon"
        name="menu_alt_03"
        size="text-4xl"
        class="cursor-pointer lg:hidden"
        @click="openMobileNavbar"
      />
    </div>

    <!-- Subscription end -->
    <div
      v-if="stillInFreePeriod"
    >
      {{ t('ttmt.plans.free_period_ended_in', { in: currentSubscription.attributes.free_period_ended_in }) }}
      &bull;
      <router-link
        :to="{ name: 'subscriptions' }"
        class="app-link"
      >
        {{ t('ttmt.users.profile.manage_my_subscription') }}
      </router-link>
    </div>

    <!-- Right side -->
    <div class="flex items-center">
      <!-- Notifications -->
      <the-notifications />

      <!-- User options -->
      <app-rounded-picture
        id="userDropdown"
        :picture-url="avatarUrl"
        :icon-name="userIcon"
        picture-size="10"
        icon-size="sm"
        with-shadow
        class="cursor-pointer ml-4"
        @click="toggleUserDropdown"
      />
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import TheNotifications from '@shared/components/features/notifications/TheNotifications.vue'
import useResource from '@shared/hooks/resources/resource'
import useAuthUser from '@shared/hooks/auth/authUser'

const store = useStore()
const { t } = useI18n()

const {
  getIconFromResourceType,
  getImageUrlFromResource,
  USER_TYPE,
} = useResource()

const {
  currentSubscription,
  stillInFreePeriod,
} = useAuthUser()

// ---------- MOBILE NAVBAR ----------

function openMobileNavbar() {
  store.commit('navbar/OPEN_MOBILE')
}

// ---------- USER ----------

const user = computed(() => (
  store.state.auth.user
))

const userIcon = getIconFromResourceType(USER_TYPE)

const avatarUrl = computed(() => (
  getImageUrlFromResource(user.value, 'xxs')
))

// ---------- USER DROPDOWN ----------

function toggleUserDropdown() {
  if (store.state.dropdown.open) {
    store.commit('dropdown/CLOSE')
    return
  }

  store.commit('dropdown/OPEN')
}
</script>
