<template>
  <form
    id="poi-form"
    novalidate
    @submit.prevent="onSubmit"
  >
    <form-global-errors
      class="mb-3"
    />

    <fieldset
      :disabled="submitting"
    >
      <form-translatable-hint
        v-if="isStepFullForm"
        class="mb-4"
      />

      <app-alert
        v-if="isStepCheckDuplicate"
        design="info"
        :title="t('ttmt.points_of_interest.duplicates.check_explanation_title')"
        class="mb-4"
      >
        <p>
          {{ t('ttmt.points_of_interest.duplicates.check_explanation1') }}
        </p>

        <p>
          {{ t('ttmt.points_of_interest.duplicates.check_explanation2') }}
        </p>

        <p>
          {{ t('ttmt.points_of_interest.duplicates.check_explanation3') }}
        </p>
      </app-alert>

      <!-- Step 1 form fields -->
      <form-fields-duplicates
        v-if="isStepCheckDuplicate"
        :full-width="true"
        class="text-left"
      />

      <!-- Step 2 form fields -->
      <form-fields-full
        v-if="isStepFullForm"
        class="text-left"
      />

      <!-- Duplicates modal -->
      <app-overlay
        :show="duplicatesModalDisplayed"
        @clicked="handleDuplicatesModalClose"
      >
        <app-modal
          ref="duplicatesModal"
          max-width-classes="max-w-full"
          text-align-classes="text-left"
          @closed="handleDuplicatesModalClose"
        >
          <div class="float-right flex items-center">
            <!-- Continue creation -->
            <app-button
              :label="t('ttmt.points_of_interest.duplicates.continue_creation')"
              emphasis="medium"
              class="mr-2"
              @click="continueCreation"
            />

            <!-- Use selected POI's for claim/contest -->
            <app-button
              v-if="selectedDuplicateResource"
              :label="t('ttmt.points_of_interest.duplicates.use_poi')"
              @click="handleUsePoi(selectedDuplicateResource?.id)"
            />
          </div>

          <div class="flex clear-both">
            <!-- Explanations about duplicates found -->
            <div class="min-w-1/4 w-1/4">
              <div class="text-left text-sm">
                <p class="mb-2">
                  {{ t('ttmt.points_of_interest.duplicates.found_explanation1') }}
                </p>

                <p>
                  {{ t('ttmt.points_of_interest.duplicates.found_explanation2') }}
                </p>
              </div>

              <!-- Duplicates list -->
              <app-card-item
                v-for="(duplicate, key) in duplicatesRelevantEnough"
                :key="key"
                class="mb-2 p-3 cursor-pointer"
                @click="selectDuplicate(duplicate.id)"
              >
                <point-of-interest-duplicate-card
                  :resource="duplicate"
                  :selected="duplicate.id === selectedDuplicateId"
                />
              </app-card-item>
            </div>

            <!-- Selected POI preview -->
            <div class="ml-8 flex-1">
              <point-of-interest-show
                v-if="selectedDuplicateResource"
                :resource="selectedDuplicateResource"
                :with-actions="false"
                :with-locale-selector="false"
                :with-employees="false"
                :with-reservations="false"
              />

              <!-- Placeholder / Hint -->
              <p
                v-else
                class="font-bold text-xl fixed left-1/2 top-1/2"
              >
                {{ t('ttmt.points_of_interest.duplicates.select_duplicate') }}
              </p>
            </div>
          </div>
        </app-modal>
      </app-overlay>
    </fieldset>

    <teleport-wrapper
      to="#poi-form-buttons"
    >
      <app-button
        form="poi-form"
        feature="save"
        :label="submitLabel"
        type="submit"
        :loading="submitting"
        :disabled="submitting || invalid"
      />
    </teleport-wrapper>
  </form>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import usePoiCreateForm from '@extranet/hooks/poiCreateForm'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import FormFieldsDuplicates from '@extranet/components/resources/point_of_interest/form/FormFieldsDuplicates.vue'
import FormFieldsFull from '@extranet/components/resources/point_of_interest/form/FormFieldsFull.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormTranslatableHint from '@extranet/components/form/FormTranslatableHint.vue'
import PointOfInterestDuplicateCard from '@extranet/components/resources/point_of_interest/PointOfInterestDuplicateCard.vue'
import PointOfInterestShow from '@extranet/components/resources/point_of_interest/PointOfInterestShow.vue'

const props = defineProps({
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not
  // generated by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
  // Handle use POI function
  usePoi: {
    type: Function,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const { t } = useI18n()

// ---------- CREATE FORM ----------

const {
  selectDuplicate,
  duplicatesRelevantEnough,
  handleDuplicatesModalClose,
  continueCreation,
  duplicatesModalDisplayed,
  selectedDuplicateResource,
  selectedDuplicateId,
  invalid,
  isStepCheckDuplicate,
  isStepFullForm,
  submitLabel,
  onSubmit,
} = usePoiCreateForm(props, { emits })

// ---------- USE POI ----------

function handleUsePoi(id) {
  props.usePoi(id)
}
</script>
