<template>
  <p class="font-bold text-xl mb-4">
    {{ t('ttmt.tutorials.extranet.begin.butler.steps.institution.subtitle.part_1') }}
  </p>

  <p class="font-bold mb-4">
    {{ t('ttmt.tutorials.extranet.begin.butler.steps.institution.subtitle.part_2') }}

    <router-link
      :to="{ name: 'institutions' }"
      class="app-link"
    >
      {{ t('ttmt.tutorials.extranet.begin.butler.steps.institution.subtitle.link') }}
    </router-link>
  </p>

  <!-- eslint-disable vue/no-v-html -->
  <p v-html="t('ttmt.tutorials.extranet.begin.butler.steps.institution.content')" />
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
