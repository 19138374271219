<template>
  <begin-modal
    :show="beginModalDisplayed"
    @closed="handleBeginModalCancel"
  />
</template>

<script setup>
import { ref } from 'vue'

import BeginModal from '@extranet/components/begin/BeginModal.vue'

// ---------- BEGIN MODAL ----------

const beginModalDisplayed = ref(true)

function handleBeginModalCancel() {
  beginModalDisplayed.value = false
}
</script>
