<template>
  <app-overlay
    :show="show"
    @clicked="handleModalClose"
  >
    <app-modal
      :max-width-classes="step === 3 ? 'max-w-screen-2xl' : 'max-w-screen-md'"
      text-align-classes="text-left"
      @closed="handleModalClose"
    >
      <template
        v-if="step === 2"
        #close
      >
        <app-button
          :label="capitalize(t('ttmt.common.later'))"
          emphasis="low"
        />
      </template>

      <template #title>
        <h4 class="text-2xl font-semibold">
          {{ t('ttmt.extranet.excursions.actions.new') }}
        </h4>
      </template>

      <excursion-form
        :key="formKey"
        :resource="excursion"
        :additional-errors="excursionFormErrors"
        :submitting="excursionFormSubmitting"
        :force-step="step"
        :force-mode="mode"
        @submitted="handleSubmit"
        @mode-selected="handleModeSelected"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import {
  ref,
  inject,
  onMounted,
  onUnmounted,
  computed,
} from 'vue'
import { useStore } from 'vuex'
import { capitalize, compact, set } from 'lodash'
import { useI18n } from 'vue-i18n'

import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import ExcursionForm from '@extranet/components/resources/excursion/main_form/ExcursionForm.vue'
import useExcursionForm from '@shared/hooks/resources/form/excursion'
import {
  fetchPointsOfInterest,
} from '@shared/http/api'

const store = useStore()
const { t } = useI18n()

const {
  resource: excursion,
  errors: excursionFormErrors,
  submitting: excursionFormSubmitting,
  handleSubmit,
} = useExcursionForm({
  onSubmitSuccess,
})

const initialStep = 1
const forceStep = store.state.modals.excursion_form.forceStep
const step = ref(forceStep ?? initialStep)
const pointOfInterestId = ref(store.state.modals.excursion_form.pointOfInterestId)
const show = ref(false)
const mode = ref()
const excursionsIndexRefresh = inject('excursions_index_refresh')
const formKey = ref(0)

function onStepInitSuccessCallback(response) {
  // Sync form resource with the created one
  excursion.value = response.data.data

  forceFormRefresh()

  // Go to next step
  step.value = 2
}

function onStepColumnsSuccessCallback() {
  handleModalClose()

  // Display flash message to user
  store.commit('flashes/ADD_FLASH', { message: t('ttmt.excursions.update.success'), type: 'success' })
}

function onSubmitSuccess(response) {
  if (step.value === 1) {
    onStepInitSuccessCallback(response)
  } else {
    onStepColumnsSuccessCallback()
  }
}

function handleModeSelected(mode) {
  switch (mode) {
    case 'create_point_of_interest':
      store.commit('modals/SET_PROPERTIES', {
        name: 'point_of_interest_form',
        display: true,
        // When the POI has been created, redirect to excursion's edit page
        on_submit_success: (response) => {
          // Add the created POI
          addPointOfInterestToRelation(response.data.data)

          // Close POI modal
          store.commit('modals/RESET', 'point_of_interest_form')

          goToColumnsStep()
        },
      })

      break
    case 'from_existing_excursions':
      goToColumnsStep('existing_excursions')

      break
    case 'from_points_of_interest':
      goToColumnsStep('points_of_interest')
  }
}

function goToColumnsStep(selectedMode = null) {
  const userPointOfInterest = store.state.auth.user.relationships.point_of_interest
  if (userPointOfInterest) {
    // Add user's POI
    addPointOfInterestToRelation(userPointOfInterest)
  }

  forceFormRefresh()

  // Go to columns step
  step.value = 3

  // Force form's mode
  mode.value = selectedMode
}

function handleModalClose() {
  // Refresh excursion's index (in order to display the created/updated excursion too)
  excursionsIndexRefresh.value += 1

  store.commit('modals/RESET', 'excursion_form')
}

function forceFormRefresh() {
  // Force the form component to refresh (to take updated "resource" prop into account)
  formKey.value += 1
}

function addPointOfInterestToRelation(pointOfInterestResource) {
  // Init relation array if not exist
  if (!excursion.value.relationships.excursions_points_of_interest) {
    excursion.value.relationships.excursions_points_of_interest = []
  }

  // Prefill relationship with POI resource
  excursion.value.relationships.excursions_points_of_interest.push({
    relationships: {
      point_of_interest: pointOfInterestResource,
    },
  })

  forceFormRefresh()
}

function fetchPois() {
  store.commit('spinner/ENABLE')

  fetchPointsOfInterest({
    'filter[id]': pointsOfInterestIds.value,
    'mode': 'index',
    'source': 'extranet',
  })
    .then((response) => {
      show.value = true
      // Prefill relationships with POI resources
      set(
        excursion.value,
        'relationships.excursions_points_of_interest',
        response.data.data?.map((poi, index) => ({
          relationships: {
            point_of_interest: poi,
          },
          attributes: {
            position: index,
          },
        })) ?? [],
      )
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

const pointsOfInterestIds = computed(() => (
  compact([
    store.state.auth.user.relationships.point_of_interest?.id, // Get POI from auth user relation
    pointOfInterestId.value, // Get POI id from store
  ])
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  // Fetch POIs first
  if (pointsOfInterestIds.value.length > 0) {
    fetchPois()
  } else {
    // Show the modal
    show.value = true
  }
})

onUnmounted(() => {
  store.commit('filters/RESET_FILTERS', 'pointsOfInterest')
})
</script>
