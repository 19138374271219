import { createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import filters from '@shared/filters'
import i18n from '@shared/i18n/vue-i18n'
import errorHandler from '@shared/errors/handler'
import interceptors from '@shared/http/interceptors'
import clickedOutside from '@shared/directives/clickedOutside'
import store from '@extranet/store/store'
import router from '@extranet/router/router'
import startApp from '@shared/startApp'
import routerGuards from '@shared/router/guards'
import TheExtranet from '@extranet/components/TheExtranet.vue'
import TeleportWrapper from '@shared/components/TeleportWrapper.vue'

import '@extranet/cookies'
import '@shared/broadcasting'
import '@shared/validation'
import '@shared/fontAwesome'

const app = createApp(TheExtranet)

errorHandler(app)
interceptors(router, store)

app.config.globalProperties.$filters = filters

// Directives
app.directive('clicked-outside', clickedOutside)

// Register global components
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('TeleportWrapper', TeleportWrapper)

// Register vue modules
app.use(router)
app.use(store)
app.use(i18n)

routerGuards(router, store, {
  auth_route_name: 'login',
  home_route_name: 'dashboard',
})

startApp(app, router, store)
