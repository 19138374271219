<template>
  <app-overlay
    :show="show"
  >
    <app-modal
      :key="componentKey"
      max-width-classes="max-w-screen-xl"
      text-align-classes="text-left"
      @closed="handleClose"
    >
      <!--
        Display begin modal
        depending on permissions
      -->
      <begin-hotelier
        v-if="dashboardHotelierAccess"
        @submitted="forceRefresh"
      />

      <begin-manager
        v-else-if="dashboardManagerAccess"
        @submitted="forceRefresh"
      />

      <begin-butler
        v-else-if="dashboardButlerAccess"
        @submitted="forceRefresh"
      />

      <begin-tourist-office
        v-else-if="dashboardTouristOfficeAccess"
        @submitted="forceRefresh"
      />

      <begin-tourist-office-employee
        v-else-if="dashboardTouristOfficeEmployeeAccess"
        @submitted="forceRefresh"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref } from 'vue'

import useAccess from '@extranet/hooks/access'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import BeginButler from '@extranet/components/begin/BeginButler.vue'
import BeginHotelier from '@extranet/components/begin/BeginHotelier.vue'
import BeginManager from '@extranet/components/begin/BeginManager.vue'
import BeginTouristOffice from '@extranet/components/begin/BeginTouristOffice.vue'
import BeginTouristOfficeEmployee from '@extranet/components/begin/BeginTouristOfficeEmployee.vue'

defineProps({
  // Display modal
  show: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'closed',
])

// ---------- MODAL ----------

function handleClose() {
  emits('closed')
}

// ---------- ACCESS ----------

const {
  dashboardHotelierAccess,
  dashboardManagerAccess,
  dashboardButlerAccess,
  dashboardTouristOfficeAccess,
  dashboardTouristOfficeEmployeeAccess,
} = useAccess()

// ---------- COMPONENT REFRESH ----------

const componentKey = ref(0)

// Force tabs component to refresh
function forceRefresh() {
  componentKey.value += 1
}
</script>
