<template>
  <div class="flex justify-between">
    <p class="font-bold text-xl">
      {{ t('ttmt.claims.pages.show.sections.claim') }}
    </p>

    <app-tutorial-button
      :tutorials="tutorials"
    />
  </div>

  <begin-stepper
    :steps="steps"
    :current-step="step"
  />

  <template v-if="isStepIndex">
    <app-alert
      design="info"
      :title="t('ttmt.claims.notice.find_or_new.title')"
      class="my-4"
    >
      {{ t('ttmt.claims.notice.find_or_new.description') }}
    </app-alert>

    <step-claim-poi-index
      :poi-select="handlePoi"
      :create-step="handleCreate"
    />
  </template>

  <template v-if="isStepCreate">
    <step-claim-create-poi
      :additional-errors="formCreatePoiErrors"
      :submitting="formCreatePoiSubmitting"
      :use-poi="handlePoi"
      @submitted="handleCreatePoiSubmit"
    />

    <div class="mt-4 flex justify-end space-x-4">
      <app-button
        :label="t('ttmt.common.actions.return')"
        emphasis="medium"
        class="ml-2"
        @click="changeStep(STEP_INDEX)"
      />

      <div id="poi-form-buttons" />
    </div>
  </template>

  <template v-if="isStepClaim">
    <template v-if="!isPoiLoading">
      <step-claim-contest-form
        v-if="pointOfInterest?.attributes?.owned_by_someone"
        :additional-errors="formContestErrors"
        :submitting="formContestSubmitting"
        @submitted="handleContestSubmit"
      />

      <step-claim-claim-form
        v-else
        :additional-errors="formClaimErrors"
        :submitting="formClaimSubmitting"
        @submitted="handleClaimSubmit"
      />
    </template>

    <div class="mt-4 flex justify-end space-x-4">
      <app-button
        :label="t('ttmt.common.actions.return')"
        emphasis="medium"
        @click="changeStep(STEP_INDEX)"
      />

      <div id="claim-form-buttons" />
    </div>
  </template>
</template>

<script setup>
import {
  computed,
  ref,
  shallowRef,
} from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  claimPointOfInterest,
  contestPointOfInterest,
  fetchPointOfInterest,
  storePointOfInterest,
} from '@shared/http/api'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppTutorialButton from '@extranet/components/ui/help/AppTutorialButton.vue'
import HelpClaimDocuments from '@extranet/components/begin/help/HelpClaimDocuments.vue'
import BeginStepper from '@extranet/components/begin/BeginStepper.vue'
import StepClaimClaimForm from '@extranet/components/begin/steps/claim/StepClaimClaimForm.vue'
import StepClaimPoiIndex from '@extranet/components/begin/steps/claim/StepClaimPoiIndex.vue'
import StepClaimContestForm from '@extranet/components/begin/steps/claim/StepClaimContestForm.vue'
import StepClaimCreatePoi from '@extranet/components/begin/steps/claim/StepClaimCreatePoi.vue'

const store = useStore()
const { t } = useI18n()

const emits = defineEmits([
  'submitted',
])

// ---------- TUTORIALS ----------

const tutorials = computed(() => ([
  {
    menuItem: t('ttmt.tutorials.extranet.begin.shared.claims.documents.label'),
    component: HelpClaimDocuments,
  },
]))

// ---------- STEPS ----------

const STEP_INDEX = 1
const STEP_CREATE = 2
const STEP_CLAIM = 3

const step = ref(STEP_INDEX)

const isStepIndex = computed(() => (
  step.value === STEP_INDEX
))

const isStepCreate = computed(() => (
  step.value === STEP_CREATE
))

const isStepClaim = computed(() => (
  step.value === STEP_CLAIM
))

function changeStep(nextStep) {
  step.value = nextStep
}

function handleCreate() {
  changeStep(STEP_CREATE)
}

function handlePoi(id) {
  fetchPoi(id)
  changeStep(STEP_CLAIM)
}

const steps = shallowRef([
  {
    step: STEP_INDEX,
    name: t('ttmt.claims.form_wizard.steps.search'),
  },
  {
    step: STEP_CREATE,
    name: t('ttmt.claims.form_wizard.steps.create'),
  },
  {
    step: STEP_CLAIM,
    name: t('ttmt.claims.form_wizard.steps.claim'),
  },
])

// ---------- FORM CLAIM SUBMIT ----------

const formClaimSubmitting = ref(false)
const formClaimErrors = ref({})

function handleClaimSubmit(attributes) {
  if (!formClaimSubmitting.value) {
    formClaimSubmitting.value = true
    const params = {
      data: {
        type: 'pointsofinterest',
        attributes: { ...attributes },
      },
    }

    claimPointOfInterest(pointOfInterest.value.id, params)
      .then(() => {
        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('ttmt.tutorials.extranet.begin.messages.claim'),
            type: 'success',
          },
        )

        handleRefresh()
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formClaimErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formClaimSubmitting.value = false
      })
  }
}

// ---------- FORM CONTEST SUBMIT ----------

const formContestSubmitting = ref(false)
const formContestErrors = ref({})

function handleContestSubmit(attributes) {
  if (!formContestSubmitting.value) {
    formContestSubmitting.value = true

    const params = {
      data: {
        type: 'pointsofinterest',
        attributes: { ...attributes },
      },
    }

    contestPointOfInterest(pointOfInterest.value.id, params)
      .then(() => {
        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('ttmt.tutorials.extranet.begin.messages.contest'),
            type: 'success',
          },
        )

        handleRefresh()
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formContestErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formContestSubmitting.value = false
      })
  }
}

// ---------- FORM POI CREATE SUBMIT ----------

const formCreatePoiSubmitting = ref(false)
const formCreatePoiErrors = ref({})

function handleCreatePoiSubmit(attributes) {
  if (!formCreatePoiSubmitting.value) {
    formCreatePoiSubmitting.value = true

    const params = {
      data: {
        type: 'pointsofinterest',
        attributes: { ...attributes },
      },
    }

    storePointOfInterest(params)
      .then((response) => {
        pointOfInterest.value = response.data.data
        changeStep(STEP_CLAIM)

        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('ttmt.tutorials.extranet.begin.messages.point_of_interest'),
            type: 'success',
          },
        )
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formCreatePoiErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formCreatePoiSubmitting.value = false
      })
  }
}

// ---------- GET CURRENT POINT OF INTEREST ----------

const pointOfInterest = ref(null)
const isPoiLoading = ref(false)

function fetchPoi(poiId) {
  isPoiLoading.value = true

  fetchPointOfInterest(poiId)
    .then((response) => {
      pointOfInterest.value = response.data.data
    })
    .finally(() => {
      isPoiLoading.value = false
    })
}

// ---------- REFRESH MODAL ----------

async function handleRefresh() {
  await store.dispatch('auth/getAuthenticatedUser')
    .then(() => {
      emits('submitted')
    })
}
</script>
