<template>
  <div class="mx-auto max-w-lg">
    <p class="mb-4">
      {{ t('ttmt.exceptions.error_texts.password_missing.extranet') }}
    </p>

    <form
      novalidate
      @submit.prevent="onSubmit"
    >
      <form-global-errors class="mb-5" />

      <form-group
        name="password"
        type="password"
        hide-errors
        rules="required"
        :form-control-props="{
          autocomplete: 'new-password',
        }"
      >
        <template #hint>
          <form-password-hint-list
            :password="form.values.password"
          />
        </template>
      </form-group>

      <form-group
        name="password_confirmation"
        type="password"
        rules="confirmed:@password"
        :form-control-props="{
          autocomplete: 'new-password',
        }"
      />

      <app-button
        type="submit"
        feature="confirm"
        width="full"
        :loading="formSubmitting"
        :disabled="formSubmitting || invalid"
      />
    </form>

    <!-- Logout -->
    <app-button
      feature="logout"
      :hide-icon="false"
      emphasis="low"
      class="mt-10"
      @click="logout"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { updateCurrentUser } from '@shared/http/api'

import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import useForm from '@shared/hooks/form/form'
import useLogout from '@shared/hooks/auth/logout'
import FormPasswordHintList from '@shared/components/form/password_hint/FormPasswordHintList.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const { t } = useI18n()
const router = useRouter()
const store = useStore()

const { logout } = useLogout({
  auth_route_name: 'login',
})

const formSubmitting = ref(false)
const formErrors = ref({})

const {
  handleSubmit,
  form,
  invalid,
} = useForm({ additionalErrors: formErrors })

async function onSubmit() {
  const fieldsToSubmit = await handleSubmit()

  if (fieldsToSubmit && !formSubmitting.value) {
    formSubmitting.value = true

    const params = {
      data: {
        type: 'user',
        attributes: fieldsToSubmit,
      },
    }

    updateCurrentUser(params)
      .then(() => {
        store.dispatch('auth/getAuthenticatedUser')
          .then(() => {
            router.push({ name: 'dashboard' })
            store.commit(
              'flashes/ADD_FLASH',
              {
                message: t('ttmt.users.update.success'),
                type: 'success',
              },
            )
          })
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}
</script>
