<template>
  <aside
    :class="`
      fixed left-0 top-0 z-40 h-screen
      whitespace-nowrap overflow-y-auto
      bg-white lg:bg-theme-500
      text-theme-900 lg:text-white
      duration-300 lg:block
      ${mobileOpen ? '' : 'hidden' }
      ${decreased ? 'decreased' : ''}
    `"
  >
    <div class="flex flex-col">
      <div class="flex items-center px-9 pt-9 mb-6">
        <!-- Close navbar -->
        <app-icon
          library="coolicon"
          name="close_big"
          size="text-5xl"
          class="cursor-pointer mr-4 lg:hidden"
          @click="closeMobileNavbar"
        />

        <!-- Brand logo -->
        <img
          v-if="decreased"
          class="w-44 block"
          src="/images/shared/icons/32.png"
          alt="logo"
        >

        <img
          class="w-44 block lg:hidden"
          src="/images/shared/logos/horizontal_darkblue.svg"
          alt="logo"
        >

        <img
          :class="`w-44 hidden ${decreased ? '' : 'lg:block'}`"
          src="/images/shared/logos/horizontal_white.svg"
          alt="logo"
        >
      </div>

      <!-- Profile -->
      <div :class="`flex ${!decreased ? 'ml-4 items-center' : 'justify-center'}`">
        <app-rounded-picture
          :picture-url="avatarUrl"
          :icon-name="userIcon"
          picture-size="10"
          icon-size="sm"
          with-shadow
          :class="!decreased ? 'ml-4' : ''"
        />

        <div
          v-if="!decreased"
          class="px-4"
        >
          <p class="font-semibold">
            {{ userDisplayName }}
          </p>

          <p class="text-sm">
            {{ authUserRoles }}
          </p>

          <p
            v-if="activeSubscription?.relationships.plan?.attributes?.name"
            class="text-sm"
          >
            {{ `${t('ttmt.models.subscription')} ${activeSubscription.relationships.plan.attributes.name}` }}
          </p>
        </div>
      </div>

      <logout-button
        :minimized="decreased"
      />

      <!-- Nav items -->
      <div class="mt-4">
        <nav>
          <ul>
            <li
              v-if="user?.attributes.tutorial_state !== 'done'"
              class="bg-white text-theme-500"
            >
              <a
                class="py-2 px-9 cursor-pointer flex items-center"
                @click="handleBeginModalDisplay"
              >
                <app-icon
                  library="coolicon"
                  name="help_circle_outline"
                />

                <span
                  class="text-xl font-bold ml-4"
                  :class="!decreased ? '' : 'lg:hidden'"
                >
                  {{ t('ttmt.extranet.navbar.tutorials') }}
                </span>
              </a>
            </li>

            <nav-item
              v-for="(navItem, key) in navItems"
              :key="key"
              v-bind="navItem"
              class="py-2 px-9"
              :display-text="!decreased"
              @clicked="closeMobileNavbar"
            />

            <form-group
              v-if="!decreased && anyShortcutOptionsAvailable"
              id="creation-shortcut"
              ref="creationShortcutFormGroup"
              name="creation_shortcut"
              type="select"
              :label="false"
              :placeholder="t('ttmt.common.actions.create')"
              :form-control-props="{
                searchable: false,
                options: shortcutOptions
              }"
              class="text-theme-500 font-bold px-9 mt-2"
              @changed="handleCreationShortcutSelected"
            />
          </ul>
        </nav>

        <!-- Increase/Decrease -->
        <div class="mt-4">
          <app-icon
            v-if="decreased"
            library="coolicon"
            name="chevron_big_right"
            size="text-2xl"
            class="cursor-pointer hidden lg:block text-white float-right px-9"
            @click="increaseNavbar"
          />

          <app-icon
            v-else
            library="coolicon"
            name="chevron_big_left"
            size="text-2xl"
            class="cursor-pointer hidden lg:block text-white float-right px-9"
            @click="decreaseNavbar"
          />
        </div>
      </div>
    </div>
  </aside>

  <begin-modal
    :show="beginModalDisplayed"
    @closed="handleBeginModalCancel"
  />
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import useAuthUser from '@shared/hooks/auth/authUser'
import useNavbar from '@extranet/hooks/navbar'
import useResource from '@shared/hooks/resources/resource'
import useUser from '@shared/hooks/resources/user'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import BeginModal from '@extranet/components/begin/BeginModal.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import NavItem from '@extranet/components/layout/navbar/NavItem.vue'
import LogoutButton from '@extranet/components/auth/logout/LogoutButton.vue'

const { t } = useI18n()
const store = useStore()
const { navItems } = useNavbar()

// ---------- USER ----------

const {
  getIconFromResourceType,
  getImageUrlFromResource,
  USER_TYPE,
} = useResource()

const user = computed(() => (
  store.state.auth.user
))

const userIcon = getIconFromResourceType(USER_TYPE)

const avatarUrl = computed(() => (
  getImageUrlFromResource(user.value, 'xxs')
))

const {
  roles: authUserRoles,
  displayName: userDisplayName,
} = useUser({ resource: user.value })

// ---------- OPEN/CLOSE ----------

const mobileOpen = computed(() => (
  store.state.navbar.mobileOpen
))

function closeMobileNavbar() {
  store.commit('navbar/CLOSE_MOBILE')
}

// ---------- DECREASE/INCREASE ----------

const decreased = ref(false)

function decreaseNavbar() {
  decreased.value = true
}

function increaseNavbar() {
  decreased.value = false
}

// ---------- CREATION SHORTCUT ----------

const creationShortcutFormGroup = ref() // Template ref

function handleCreationShortcutSelected(value) {
  if (value) {
    // Clear value, it doesn't need to be displayed as selected
    creationShortcutFormGroup.value.$refs.formControlRef.$refs.multiselect.clear()

    // Display the creation modal
    store.commit('modals/SHOW', value)
  }
}

const shortcutOptions = computed(() => {
  const options = {}

  // Make options available only if user
  // is authorized to perform related actions
  if (user.value.authorizations.excursions.create) {
    options.excursion_form = t('ttmt.models.excursion')
  }

  if (user.value.authorizations.points_of_interest.create) {
    options.point_of_interest_form = t('ttmt.models.point_of_interest')
  }

  return options
})

const anyShortcutOptionsAvailable = computed(() => (
  Object.keys(shortcutOptions.value).length > 0
))

// ---------- SUBSCRIPTION ----------

const {
  activeSubscription,
} = useAuthUser()

// ---------- BEGIN MODAL ----------

const beginModalDisplayed = ref(false)

function handleBeginModalDisplay() {
  beginModalDisplayed.value = true
}

function handleBeginModalCancel() {
  beginModalDisplayed.value = false
}
</script>

<style lang="scss" scoped>
#creation-shortcut {
  :deep(.multiselect-placeholder) {
    @apply text-theme-500; // Overwrite default placeholder font color
  }
}
</style>
