<template>
  <div class="flex flex-col md:flex-row">
    <div class="w-full md:w-1/4 mr-4 mb-4">
      <stepper-wrapper
        :steps="steps"
        :active-step="activeStep"
      />
    </div>

    <div class="w-full md:w-3/4 md:px-4">
      <div v-if="activeComponent">
        <component
          :is="activeComponent"
          v-on="activeComponent.emits"
        />
      </div>

      <step-done
        v-else
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import StepClaim from '@extranet/components/begin/steps/claim/StepClaim.vue'
import StepDone from '@extranet/components/begin/steps/done/StepDone.vue'
import StepPayment from '@extranet/components/begin/steps/payment/StepPayment.vue'
import StepperWrapper from '@extranet/components/tutorials/stepper/StepperWrapper.vue'

const emits = defineEmits([
  'submitted',
])

const { t } = useI18n()
const store = useStore()

// ---------- STEPS ----------

const steps = computed(() => {
  const hotelierSteps = [
    {
      stepNumber: 0,
      stepState: t('ttmt.tutorials.extranet.begin.shared.claims.step_state'),
      stepNext: t('ttmt.tutorials.extranet.begin.shared.claims.step_next'),
      stepTitle: t('ttmt.tutorials.extranet.begin.shared.claims.title'),
      stepDescription: t('ttmt.tutorials.extranet.begin.shared.claims.description'),
      component: StepClaim,
      emits: {
        submitted: handleSubmit,
      },
    },
    {
      stepNumber: 2,
      stepState: t('ttmt.tutorials.extranet.begin.shared.payments.step_state'),
      stepNext: t('ttmt.tutorials.extranet.begin.shared.payments.step_next'),
      stepTitle: t('ttmt.tutorials.extranet.begin.shared.payments.title'),
      stepDescription: t('ttmt.tutorials.extranet.begin.shared.payments.description'),
      component: StepPayment,
      emits: {
        submitted: () => ({}),
      },
    },
  ]

  return hotelierSteps
})

const currentStep = computed(() => (
  steps.value.find(
    (step) => step.stepState === store.state.auth.user?.attributes.tutorial_state,
  )
))

const activeStep = computed(() => (
  currentStep.value?.stepNumber ?? 99
))

// ---------- COMPONENT ----------

const activeComponent = computed(() => (
  currentStep.value?.component ?? null
))

// ---------- REFRESH MODAL ----------

function handleSubmit() {
  emits('submitted')
}
</script>
