<template>
  <template v-if="loading">
    <the-loading />
  </template>

  <template
    v-else
  >
    <div class="flex">
      <!-- Left side (navbar) -->
      <the-navbar
        v-if="displayNavbar"
      />

      <!-- Right side (main content) -->
      <div
        id="main-content-wrapper"
        :class="`w-full duration-300 ${authenticated ? 'authenticated' : ''}`"
      >
        <the-header
          v-if="displayHeader"
        />

        <!-- Overlap header at scroll if there is an element teleported there -->
        <div
          id="header-scrolling-replacement"
          class="sticky top-0 right-0 bg-body z-30 min-h-16 px-8 py-4 empty:hidden"
        />

        <the-sub-header
          v-if="displayHeader"
        />

        <the-user-dropdown
          v-if="authenticated"
        />

        <main
          :class="authenticated ? 'py-4 px-8' : ''"
        >
          <the-error-boundary>
            <!-- Error components are ordered in reverse order of priority -->
            <the-forbidden
              v-if="displayForbidden"
            />

            <the-password-missing
              v-else-if="displayPasswordMissing"
            />

            <the-credential-not-verified
              v-else-if="displayCredentialNotVerified"
            />

            <router-view
              v-else
              :key="mainKey"
            />
          </the-error-boundary>
        </main>

        <the-footer
          v-if="authenticated"
        />

        <the-update-privacy-policy-modal
          v-if="authenticated && displayUpdatePrivacyPolicyModal"
        />

        <the-update-terms-condition-modal
          v-if="authenticated && displayUpdateTermsConditionModal"
        />

        <the-point-of-interest-form-modal
          v-if="authenticated && displayPointOfInterestFormModal"
        />

        <the-point-of-interest-show-modal
          v-if="authenticated && displayPointOfInterestShowModal"
        />

        <the-country-form-modal
          v-if="authenticated && displayCountryFormModal"
        />

        <the-user-show-modal
          v-if="authenticated && displayUserShowModal"
        />

        <the-excursion-form-modal
          v-if="authenticated && displayExcursionFormModal"
        />

        <the-begin-modal
          v-if="authenticated && displayBeginModal"
        />
      </div>
    </div>
  </template>

  <the-flash-list />

  <the-spinner />

  <the-reauthentication-modal
    v-if="authenticated && store.state.auth.reauthenticationNeeded"
  />
</template>

<script setup>
import { computed, provide, ref } from 'vue'
import { useStore } from 'vuex'
import { isNil } from 'lodash'

import useSessionExpiration from '@shared/hooks/auth/sessionExpiration'
import TheHeader from '@extranet/components/layout/TheHeader.vue'
import TheNavbar from '@extranet/components/layout/navbar/TheNavbar.vue'
import TheSubHeader from '@extranet/components/layout/TheSubHeader.vue'
import TheFooter from '@extranet/components/layout/TheFooter.vue'
import TheErrorBoundary from '@extranet/components/TheErrorBoundary.vue'
import TheFlashList from '@shared/components/features/flashes/TheFlashList.vue'
import TheUserDropdown from '@extranet/components/layout/dropdown/TheUserDropdown.vue'
import TheReauthenticationModal from '@extranet/components/layout/modals/TheReauthenticationModal.vue'
import TheLoading from '@extranet/components/layout/TheLoading.vue'
import TheSpinner from '@extranet/components/layout/TheSpinner.vue'
import TheUpdateTermsConditionModal from '@extranet/components/layout/modals/TheUpdateTermsConditionModal.vue'
import TheUpdatePrivacyPolicyModal from '@extranet/components/layout/modals/TheUpdatePrivacyPolicyModal.vue'
import TheBeginModal from '@extranet/components//layout/modals/TheBeginModal.vue'
import TheCountryFormModal from '@extranet/components/layout/modals/TheCountryFormModal.vue'
import TheCredentialNotVerified from '@extranet/components/layout/errors/TheCredentialNotVerified.vue'
import TheExcursionFormModal from '@extranet/components/layout/modals/TheExcursionFormModal.vue'
import TheForbidden from '@extranet/components/layout/errors/TheForbidden.vue'
import ThePasswordMissing from '@extranet/components/layout/errors/ThePasswordMissing.vue'
import ThePointOfInterestFormModal from '@extranet/components/layout/modals/ThePointOfInterestFormModal.vue'
import ThePointOfInterestShowModal from '@extranet/components/layout/modals/ThePointOfInterestShowModal.vue'
import TheUserShowModal from '@extranet/components/layout/modals/TheUserShowModal.vue'

const store = useStore()
useSessionExpiration()

const authenticated = computed(() => store.state.auth.authenticated)
const loading = computed(() => store.state.loading.active)
const user = computed(() => store.state.auth.user)

// ---------- DISPLAY LAYOUT ----------

// Adapt the layout when some errors are displayed
const errorLayout = computed(() => (
  displayForbidden.value
  || displayCredentialNotVerified.value
  || displayPasswordMissing.value
))

const displayForbidden = computed(() => (
  authenticated.value && !user.value.authorizations.access.extranet
))

const displayCredentialNotVerified = computed(() => (
  authenticated.value && !user.value.attributes.has_verified_credential
))

const displayPasswordMissing = computed(() => (
  authenticated.value && !user.value.attributes.has_password
))

// Navbar not needed while not authenticated
// Or on error layout
const displayNavbar = computed(() => (
  authenticated.value
  && !errorLayout.value
))

// Header not needed while not authenticated
// Or on error layout
const displayHeader = computed(() => (
  authenticated.value
  && !errorLayout.value
))

// ---------- ROUTER KEY ----------

const mainKey = computed(() => (
  store.state.router.mainKey
))

// ---------- INDEX REFRESH ----------

// Increase those variables in order to refresh the matching indexes
provide('points_of_interest_index_refresh', ref(1))
provide('excursions_index_refresh', ref(1))

// ---------- MODALS ----------

const displayUpdatePrivacyPolicyModal = computed(() => (
  !user.value.attributes.privacy_policy_accepted
  || user.value.attributes.privacy_policy_version !== store.state.terms.privacyPolicy.attributes.version
))

const displayUpdateTermsConditionModal = computed(() => (
  !user.value.attributes.terms_condition_accepted
  || user.value.attributes.terms_condition_version !== store.state.terms.termsCondition.attributes.version
))

const displayPointOfInterestFormModal = computed(() => (
  store.state.modals.point_of_interest_form.display
))

const displayPointOfInterestShowModal = computed(() => (
  store.state.modals.point_of_interest_show.display
))

const displayCountryFormModal = computed(() => (
  isNil(store.state.auth.user.relationships.address?.attributes?.country)
  || store.state.auth.user.relationships.address?.attributes?.country === ''
))

const displayUserShowModal = computed(() => (
  store.state.modals.user_show.display
))

const displayExcursionFormModal = computed(() => (
  store.state.modals.excursion_form.display
))

const displayBeginModal = computed(() => (
  user.value.attributes.tutorial_state !== 'done'
))
</script>
