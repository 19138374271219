<template>
  <p class="font-bold text-xl mb-4">
    {{ t('ttmt.tutorials.extranet.begin.shared.payments.description') }}
  </p>

  <p class="font-bold">
    {{ t('ttmt.tutorials.extranet.begin.shared.payments.subtitle_1') }}
  </p>

  <p class="font-bold">
    {{ t('ttmt.tutorials.extranet.begin.shared.payments.subtitle_2') }}
  </p>

  <ol class="mt-4 list-decimal list-inside">
    <li>
      {{ t('ttmt.tutorials.extranet.begin.shared.payments.steps.my_profile.part_1') }}

      <router-link
        :to="{ name: 'show-profile' }"
        class="app-link"
      >
        {{ t('ttmt.tutorials.extranet.begin.shared.payments.steps.my_profile.part_2') }}
      </router-link>
    </li>

    <!-- eslint-disable vue/no-v-html -->
    <li v-html="t('ttmt.tutorials.extranet.begin.shared.payments.steps.bank.create')" />
  </ol>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
