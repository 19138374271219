<template>
  <p class="font-bold text-xl mb-4">
    {{ t('ttmt.tutorials.extranet.begin.done.title') }}
  </p>

  <p>
    {{ t('ttmt.tutorials.extranet.begin.done.description') }}
  </p>

  <p class="mt-4">
    {{ t('ttmt.tutorials.extranet.begin.done.faq.part_1') }}

    <router-link
      :to="{ name: 'faq' }"
      class="app-link"
    >
      {{ t('ttmt.tutorials.extranet.begin.done.faq.link') }}
    </router-link>

    {{ t('ttmt.tutorials.extranet.begin.done.faq.part_2') }}
  </p>

  <!-- eslint-disable vue/no-v-html -->
  <p
    class="mt-4"
    v-html="t('ttmt.tutorials.extranet.begin.done.help')"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
